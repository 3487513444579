<template>
  <div>
    <marquee :scrollamount="scrollamount" class="notice-bar" width="100%" behavior="scroll">
      <div v-html="text"></div>
    </marquee>
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
      <ul class="ul-item">
        <li
            class="li-item"
            v-for="(item, index) in listData"
            :key="index"
        >
          <div :style="{'background': `url(${item.ornamentLevelImg})`}" class="back">
            <img :src="item.imageUrl" class="itemImg"/>
            <p class="textover-f">{{ item.itemName }}</p>

            <!-- Hover Box -->
<!--            <div class="hover-box">-->
<!--              <p>中奖用户昵称: {{ item.holderUserNickName }}</p>-->
<!--              <p>掉落箱子: {{ item.boxName }}</p>-->
<!--              <p>装饰名称: {{ item.ornamentName }}</p>-->
<!--            </div>-->
          </div>
        </li>
      </ul>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import {getWeapon, getArticle} from "@/api/index";  // Import the new API call

export default {
  name: "Example03Basic",

  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      timer: null,
      data: {
        page: 1,
        size: 15,
        orderByFie: 0,
        status: [0, 2, 3, 5],
        source: [1, 2, 3, 4],
      },
      scrollamount: 6,
      listData: [],
      userMap: {},  // Object to store user data by userId
      text: null,
      classOption: {
        limitMoveNum: 2,
        direction: 3
      }
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.getList(); // Load initial data and preload usernames
    this.timer = setInterval(() => {
      // Placeholder for repeating operations
    }, 5000);
  },
  methods: {
    getList() {
      getWeapon(this.data).then(res => {
        console.log(JSON.stringify(res.data.data))
        // Initialize holderUserNickName to 'loading' for each item
        this.listData = res.data.data.map(item => ({
          ...item
        })); // Preload user data after loading list data
      });

      getArticle("gg").then(res => {
        this.text = res.data.data.contentList[0].content;
      });
    },

  }
};
</script>

<style lang="scss" scoped>
.itemImg {
  width: 80px;
  height: 80px;
}

.hover-box {
  display: none; /* Hide the hover box initially */
  position: absolute;
  top: 0;
  left: -5%;
  width: 110px;
  background-color: rgba(0, 0, 0, 0.8); /* Slightly transparent background for a cool effect */
  color: #fff;
  padding: 10px;
  border-radius: 8px; /* Rounded corners */
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  opacity: 0; /* Start hidden */
  transform: translateY(-10px); /* Start slightly above */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for fade-in effect */
}

.li-item {
  position: relative; /* Make the list item a positioned element */
}

.li-item:hover .hover-box {
  display: block; /* Show the hover box on hover */
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Move to position */
}

@media (max-width: 550px) {
  .warp {
    height: 80px !important;
  }
  .li-item {
    width: 75px !important;
    height: 80px !important;
    font-size: 7px;

    img {
      width: 60px !important;
      height: 60px !important;
    }
  }
}

.notice-bar {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 32px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  overflow-y: auto;

  div {
    height: 32px;
  }
}

.warp {
  width: 100%;
  height: 112px;
  margin: 0 auto;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        width: 105px;
        height: 112px;
        margin-left: 10px;
        color: #fff;
        text-align: center;

        .back {
          padding: 4px;
          background-size: 100% 100% !important;
        }

        p {
          text-align: center;
          font-size: 10px;
        }
      }
    }
  }
}
</style>
