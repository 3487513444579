// websocket.js

const WebSocketPlugin = {
  install(Vue, options) {
 

    const socket = new WebSocket(options.url);
 
    Vue.prototype.$socket = socket;
 
    socket.addEventListener('open', () => {
      console.log('WebSocket连接成功');
    });
 
    socket.addEventListener('message', (event) => {
      console.log('Received message:', event.data);
      Vue.prototype.$emit('websocket-message', event.data);
    });
 
    socket.addEventListener('close', (event) => {
      console.log('WebSocket连接关闭', event);
      Vue.prototype.$emit('websocket-close', event);
    });
 
    socket.addEventListener('error', (error) => {
      console.error('WebSocket连接错误', error);
      Vue.prototype.$emit('websocket-error', error);
    });
    socket.close();
    this.$sockets.onclose = () => {
      console.log('WebSocket 连接已关闭');
    };
    
  },
};
 
export default WebSocketPlugin;