<template>
  <div class="mvp">
    <div
      class="out_II_I column animate__slideInDown animate__animated"
      ref="confirmel"
    >
      <div class="out_II_I_I">玩法说明</div>
      <div class="out_II_I_II">
        <div
          style="text-align: left; width: 100%"
          v-html="text"
          v-loading="loading"
        ></div>
      </div>
      <div class="out_II_I_III center" @click="handleok(), playAudio()">
        知道了
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      loading: false,
    };
  },

  beforeCreate() {
    this.$bus.$on("nr_illustrate", (res) => {
      this.recerL(res);
      const state = { title: "title", url: "#" };
      window.history.pushState(state, state.title, state.url);
    });
  },
  beforeDestroy() {
    this.$bus.$off("nr_illustrate");
  },
  mounted() {
    window.addEventListener("popstate", () => {
      const element = this.$refs.confirmel;
      element.className += " animate__slideOutDown";

      setTimeout(() => {
        // this.Confirm = false;
        this.$bus.$emit("is_illustrate", false);
      }, 400);
    });
  },
  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    recerL(res) {
      this.loading = true;
      // this.text = res;
      this.loading = false;
      //   console.log(res, "mmm");
      // if (res==1) {
      // this.obj = {
      //   name: res,
      //   age: 12
      //   // }
      // };
      if (res == "dream") {
        this.text = `<p>1、选择您想要的目标饰品。</p>
      <p>
        2、随意选取成功百分比以及对应的ROLL点区间，最低追梦概率为5%，最高75%。
      </p>
      <p>3、追梦金额会根据饰品价值和所选区间相应变化。</p>
      <p>
        4、追梦成功，您将获得目标饰品；追梦失败，您将获得随机安慰奖（无论是否成功，追梦完
        毕后指针会默认恢复到最高追梦概率处）。
      </p>`;
      } else if (res == "pk") {
        this.text = `<p>
        1、
        创建竞技场时，可以选择乱斗人数（2人对战，3人对战和4人对战），一场乱斗最多可以选择
        12个盲盒。
      </p>
      <p>
        2、
        竞技场入场费用即本场竞技开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还费用。
      </p>
      <p>3、 当一场竞技有多名赢家均分奖励时，无法平均分配，则随机分配</p>
      <p>
        4、
        每场游戏的结果受到（创建时间，玩家ID，玩家位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。
      </p>`;
      } else if (res == "roll") {
        this.text = `<p>
        1、选择加入roll房间，根据房间规则可以加入房间。
      </p>
      <p>
        2、达到加入条件后，即可加入房间。
      </p>
      <p>3、加入房间，到达房间开奖日期后即可随机分配房间内的饰品。</p>`;
      }
      this.$forceUpdate();
      console.log(this.obj);
    },
    handleok() {
      const element = this.$refs.confirmel;
      element.className += " animate__slideOutDown";

      setTimeout(() => {
        // this.Confirm = false;
        this.$router.back();
        this.$bus.$emit("is_illustrate", false);
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.mvp {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  @media (max-width: 550px) {
    width: 100%;
  }
  .out_II_I {
    width: 95%;
    margin: 0 auto;
    overflow-y: auto;
    position: relative;
    height: 500px;
    background: #101115;
    border-radius: 23px 23px 23px 23px;
    margin-top: 70px;
    // overflow: hidden;
    justify-content: space-around;
    padding: 39px 21px 26px 21px;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #25f484;
      border-radius: 5px;
    }

    @media (max-width: 550px) {
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
    @media (max-width: 550px) {
      margin-top: 150px;
      height: 500px;
    }
    .out_II_I_I {
      font-size: 28px;
      text-align: center;

      @media (max-width: 550px) {
        font-size: 20px;
      }
    }
  }
  .out_II_I_II {
    color: #727477;
    font-size: 19px;
  }
  .out_II_I_III {
    width: 216px;
    height: 46px;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;
    z-index: 3;
    font-size: 23px;
    background: linear-gradient(
      360deg,
      rgba(37, 244, 132, 0.5) 0%,
      rgba(37, 244, 132, 0.25) 100%
    );
    opacity: 1;
    border: 1px solid #25f484;
    @media (max-width: 550px) {
      font-size: 17px;
      width: 154px;
      height: 33px;
    }
  }
  .out_II_I::before {
    content: ""; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -222deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
