<template>
  <div class="plusr">
    <svg
      t="1706235001348"
      class="icon"
      viewBox="0 0 3783 1024"
      version="1.1"
      p-id="2783"
      width="100%"
      height="100%"
    >
      <path
        d="M3618.751131 1024H164.488688C74.135747 1024 0 949.864253 0 859.511312v-695.022624C0 74.135747 74.135747 0 164.488688 0h3454.262443c90.352941 0 164.488688 74.135747 164.488688 164.488688v695.022624c0 90.352941-74.135747 164.488688-164.488688 164.488688zM164.488688 46.334842C99.61991 46.334842 46.334842 99.61991 46.334842 164.488688v695.022624c0 64.868778 53.285068 118.153846 118.153846 118.153846h3454.262443c64.868778 0 118.153846-53.285068 118.153846-118.153846v-695.022624c0-64.868778-53.285068-118.153846-118.153846-118.153846H164.488688z"
        fill="#727477"
        p-id="2784"
      />
      <path
        d="M1026.316742 32.434389h46.334842v968.39819h-46.334842zM284.959276 488.832579h509.683258v46.334842h-509.683258zM2972.38009 488.832579h509.683258v46.334842h-509.683258z"
        fill="#727477"
        p-id="2785"
      />
      <path
        d="M3201.737557 257.158371h46.334841v509.683258h-46.334841zM2689.737557 32.434389h46.334841v968.39819h-46.334841z"
        fill="#727477"
        p-id="2786"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>
<style scoped lang="scss">
.plusr {
  margin: 0 auto;
  width: 70%;
  height: auto;
}
</style>
