<template>
  <div>
    <svg
      t="1705892723930"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      p-id="2819"
      id="mx_n_1705892723931"
      width="100%"
      height="100%"
    >
      <path
        d="M544 18.496l375.04 216.512-76.352 44.16a56.832 56.832 0 0 0-24 71.168l3.2 6.4a56.832 56.832 0 0 0 71.168 24l6.4-3.2 55.936-32.32v385.856a64 64 0 0 1-32 55.424L576 987.008V896a64 64 0 0 0-56.512-63.552L512 832a64 64 0 0 0-63.552 56.512L448 896v91.008L100.608 786.496a64 64 0 0 1-32-55.424V328.128l85.44 49.408a56.832 56.832 0 0 0 73.6-14.848l3.968-5.952a56.832 56.832 0 0 0-14.848-73.6l-5.952-3.968L119.68 226.496 480 18.496a64 64 0 0 1 64 0zM511.744 678.4a40.768 40.768 0 0 0-30.272 12.48 43.328 43.328 0 0 0-12.16 32c0 13.056 3.84 23.744 12.16 32.064 8.256 8.32 18.112 13.056 30.272 13.056 12.096 0 22.016-4.16 30.272-12.48a45.12 45.12 0 0 0 12.672-32.64 45.632 45.632 0 0 0-12.16-32 41.984 41.984 0 0 0-30.784-12.48z m6.4-358.4c-41.344 0-73.792 12.48-97.792 37.44-24.64 24.96-36.352 59.392-36.352 103.296h63.744c0-24.96 4.48-44.544 13.952-58.176 10.624-16.64 27.968-24.32 52.48-24.32 19.072 0 34.176 5.312 44.8 16.64 10.048 11.264 15.616 26.688 15.616 46.272 0 14.848-4.992 29.12-15.104 42.24l-6.656 8.256c-36.352 34.432-58.176 59.392-65.408 75.456-7.872 16-11.2 35.584-11.2 58.24V633.6h64.256v-8.32c0-14.272 2.816-26.752 8.384-38.592 5.056-10.688 12.352-20.8 22.4-29.696 26.816-24.96 43.008-40.96 48-46.912 13.44-19.008 20.736-43.392 20.736-73.088 0-36.224-11.2-64.704-33.536-85.504C584.064 330.112 554.496 320 518.144 320z"
        fill="#323436"
        p-id="2820"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>