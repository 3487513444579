  <template>
    <div id="app" class="app">
      <div v-if="loading" class="loading-screen">
        <p>Loading... {{ loadProgress }}%</p>
        <div class="progress-bar-container">
          <div class="progress-bar" :style="{ width: loadProgress + '%' }"></div>
        </div>
      </div>


      <div v-else
        class="bg zuo"
        :style="{ width: (screenWidth - 525) / 2 + 'px' }"
      ></div>
      <div class="content">
        <headerTop id="headerTop"></headerTop>
        <!-- animate__animated animate__fadeInLeft -->
        <router-view class="appview" v-if="!$route.meta.iskeep"></router-view>
        <keep-alive>
          <router-view class="appview" v-if="$route.meta.iskeep"></router-view>
        </keep-alive>
        <!-- <login v-if="show" class="App_login"></login> -->
        <WipeSidebar :slider="slider" class="cesildera"></WipeSidebar>
        <slider v-if="slider" class="App_slider"></slider>
        <illustrate v-if="isillustrate" class></illustrate>

        <bottomUser id="bottomUser"></bottomUser>
      </div>
      <div
        class="bg you"
        :style="{ width: (screenWidth - 525) / 2 + 'px' }"
      ></div>
    </div>
  </template>
  <script></script>
  <script>
  import login from "@/views/Home/common/login.vue";
  import slider from "@/components/sliderUser.vue";
  import { getToken } from "@/api/cookie";
  import resources from '@/assets/resources.json';
  import headerTop from "@/components/headerTop.vue";
  import bottomUser from "@/components/bottomUser.vue";
  import WipeSidebar from "@/components/WipeSidebar.vue";
  import store from "@/vuex/store";
  export default {
    data() {
      return {
        he: 0,
        // width: window.innerWidth
        show: false,
        slider: false,
        isillustrate: false,
        w: document.documentElement.clientWidth,
        screenWidth: document.body.clientWidth, // 屏幕宽度
        loading: true, // Loading state
        loadProgress: 0,  // Progress percentage
        totalResources: 0, // Total number of resources to load
        loadedResources: 0, // Resources that have been loaded
      };
    },
    components: {
      login,
      headerTop,
      slider,
      bottomUser,
      WipeSidebar,
    },
    mounted() {
      const that = this;

      this.loadResources()
          .then(() => {
            console.log("All resources have been loaded.");
            this.loading = false; // Hide loading screen when all resources are loaded
          })
          .catch((error) => {
            console.error("Error loading resources:", error);
            this.loading = false; // Proceed even if some resources fail to load
          });

      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth;
          that.screenWidth = window.screenWidth;
        })();
      };
      this.$nextTick(() => {
        console.log("播放音乐");
        this.$store.commit("playAudio");
      });
      console.log("window.history.state", window.history.state);
      if (window.history.state && window.history.state.title) {
        if (this.$store.state.LOGIN_IS_SHOW) {
          return;
        } else {
          window.history.back();
        }
      }
      let aa = document.getElementById("headerTop").offsetHeight;
      let bb = document.getElementById("bottomUser").offsetHeight;
      this.he = aa + bb;
      console.log("路由信息", this.$route);

      this.getToken();

      this.$bus.$on("close", (data) => {
        this.show = data;
        if (!data) {
          if (this.$route.query.fromApp) {
            this.$router.back();
          } else {
            this.$router.push('/');
          }
        }
      });

      this.$bus.$on("is_illustrate", (data) => {
        this.isillustrate = data;
      });
      this.$bus.$on("sliderbtn", (data) => {
        this.slider = data;
      });
    },
    methods: {
      loadResources() {
        console.log("Starting to load all resources...");

        const requireImages = require.context('@/assets/image', false, /\.(png|jpe?g|svg)$/);
        const imageFiles = requireImages.keys().map(file => requireImages(file));
        console.log(imageFiles);

        const requireImages2 = require.context('@/assets', false, /\.(png|jpe?g|svg)$/);
        const imageFiles2 = requireImages2.keys().map(file => requireImages2(file));
        console.log(imageFiles2);

        const requireCss = require.context('@/assets/style', false, /\.(css)$/);
        const cssFiles = requireCss.keys().map(file => requireCss(file));
        console.log(cssFiles);

        const requireAudio = require.context('@/assets', false, /\.(mp3|wav)$/);
        const audioFiles = requireAudio.keys().map(file => requireAudio(file));
        console.log(audioFiles);

        const hardcodedImages = resources.images;

        console.log(`Loading ${hardcodedImages.length} hardcoded images...`);
        console.log(`Loading ${imageFiles.length} images...`);
        console.log(`Loading ${imageFiles2.length} images...`);
        console.log(`Loading ${cssFiles.length} CSS files...`);
        console.log(`Loading ${audioFiles.length} audio files...`);
        // Calculate total number of resources
        this.totalResources = imageFiles.length + cssFiles.length + imageFiles2.length + audioFiles.length + hardcodedImages.length ;

        return Promise.all([
          ...this.loadImages(imageFiles),
          ...this.loadImages(imageFiles2),
          ...this.loadImages(hardcodedImages),
          ...this.loadCSS(cssFiles),
          ...this.loadAudio(audioFiles),
        ]);
      },

      updateProgress() {
        this.loadedResources += 1;
        this.loadProgress = Math.round((this.loadedResources / this.totalResources) * 100);
      },

      loadImages(imageFiles) {
        return imageFiles.map((url) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
              this.updateProgress();  // Update progress when the image is loaded
              resolve();
            };
            img.onerror = () => {
              console.error(`Failed to load image: ${url}`);
              this.updateProgress();  // Update progress even on failure
              resolve(); // Continue even if an image fails
            };
          });
        });
      },

      loadCSS(cssFiles) {
        return cssFiles.map((url) => {
          return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = url;
            link.onload = () => {
              this.updateProgress();  // Update progress when CSS is loaded
              resolve();
            };
            link.onerror = () => {
              console.error(`Failed to load CSS: ${url}`);
              this.updateProgress();  // Update progress even on failure
              resolve(); // Continue even if CSS fails
            };
            document.head.appendChild(link);
          });
        });
      },
      //
      // loadJS(jsFiles) {
      //   return jsFiles.map((url) => {
      //     return new Promise((resolve, reject) => {
      //       const script = document.createElement('script');
      //       script.src = url;
      //       script.onload = () => {
      //         this.updateProgress();  // Update progress when JS is loaded
      //         resolve();
      //       };
      //       script.onerror = () => {
      //         console.error(`Failed to load script: ${url}`);
      //         this.updateProgress();  // Update progress even on failure
      //         resolve(); // Continue even if JS fails
      //       };
      //       document.body.appendChild(script);
      //     });
      //   });
      // },
      loadAudio(audioFiles) {
        return audioFiles.map((url) => {
          return new Promise((resolve, reject) => {
            const audio = new Audio(); // Create a new Audio object
            audio.src = url; // Set the source of the audio to the file URL

            // When the audio file is fully loaded, resolve the promise
            audio.onloadeddata = () => {
              this.updateProgress(); // Call the function to update progress
              resolve();
            };

            // If there's an error loading the audio, reject the promise and log the error
            audio.onerror = () => {
              console.error(`Failed to load audio: ${url}`);
              this.updateProgress(); // Still update progress even if the audio fails to load
              resolve(); // Continue loading even if one audio file fails
            };
          });
        });
      },
      getToken() {
        if (getToken()) {
          this.show = false;
          this.$store.commit("LOGIN_IS_SHOW", false);

          this.$bus.$emit("islogin", this.show);
        } else {
          setTimeout(() => {
            this.$router.push("/login");
            this.show = true;
            this.$bus.$emit("islogin", this.show);
            this.$store.commit("LOGIN_IS_SHOW", true);
          }, 500);
        }
      },
    },
    watch: {},
  };
  </script>

  <style lang="scss">
  #bottomUser {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 91px;
    left: 0;
    background-color: #101115;
    font-size: 14px;
    display: flex;
    align-items: center;
    @media (max-width: 550px) {
      height: 65px !important;
      font-size: 10px !important;
      position: fixed !important;
      bottom: 0 !important;
    }
  }
  .App_slider {
    position: absolute;
    right: 15px;
    top: 30%;
    animation: slider 0.3s linear;
  }
  @keyframes slider {
    0% {
      right: -72px;
    }
    75% {
      right: 18px;
    }
    100% {
      right: 15px;
    }
  }
  .App_login {
    animation: mymove 0.3s linear;
  }
  @keyframes mymove {
    0% {
      top: -10px;
      opacity: 0.7;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  .cesildera {
    position: absolute;
    right: 30px;
    bottom: 200px;
  }
  .appview {
    overflow-y: scroll;
    position: relative;
    height: 100vh;
    padding-bottom: 200px;
  }
  .appview::-webkit-scrollbar {
    display: none;
  }
  .appview::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    opacity: 0.2;
  }
  .appview::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  .appview::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  .content {
    text-align: center;
    margin: 0 auto;
    color: #fff;
    height: 100vh;
    width: 100vw;
    position: relative;
    font-size: 1rem;

    background: #181a1c;
    max-width: 525px;
  }
  .zuo {
    background: url("../public/1.jpg") no-repeat right center;
    background-size: cover;
  }
  .you {
    background: url("../public/you.jpg") no-repeat left center;
    background-size: cover;
  }
  #app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: "Microsoft Yahei";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    height: 100vh;
    width: 100vw;
    position: relative;
    font-size: 1rem;
    -moz-user-select: -moz-none;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    justify-content: center;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    text-decoration: none;
  }
  img {
    vertical-align: middle;
  }
  svg {
    display: block;
    margin: auto;
  }

  .loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #181a1c;
    color: white;
    font-size: 1.5rem;
    z-index: 9999;
  }

  .progress-bar-container {
    width: 80%;
    height: 10px;
    background-color: #555;
    border-radius: 5px;
    margin-top: 20px;
  }

  .progress-bar {
    height: 100%;
    background-color: #4caf50; /* Green color */
    width: 0;
    transition: width 0.4s ease; /* Smooth transition */
    border-radius: 5px;
  }
  </style>
